


export default {
    created() {

        let t = this;

        t.loadSelectorData();

    },

    activated() {

        let t = this;

        if (B.addressPage) {
            if (B.addressPage.from == 'order')
                this.selectMode = true;
        }

    },
    deactivated() {
        this.selectMode = false;
        delete B.addressPage;
    },
    methods: {

        loadSelectorData() {

            let t = this;

            axios.get('location').then(res => {
                if (res.data.status == 1) {
                    t.list.location = res.data.options.items;
                    t.dict.location = listToDict(t.list.location);

                    t.loadData();
                }
            });

        },
        loadData() {
            let t = this;

            axios.get('user/address').then(res => {
                if (res.data.status == 1) {
                    t.list.address = res.data.options.items;
                    if (t.list.address.length > 0) {
                    } else {
                        t.showNewAddressForm = true;
                        t.addressForm.is_first = true;
                        if (t.list.location.length == 1)
                            t.addressForm.location_id = t.list.location[0].id;
                    }
                }
            });

        },

        saveAddress() {

            let t = this;

            let f = t.addressForm;

            if (!f.location_id) {
                t.errMsg = "请选择送餐点";
                return;
            }

            if (!f.name) {
                t.errMsg = "请输入收件人名称";
                return;
            }

            if (!/^1\d{10}$/.test(f.tel)) {
                t.errMsg = "请输入手机号(11位数字)";
                return;
            }

            if (t.busying)
                return;

            t.busying = true;

            if (t.selectedAddress)
                axios.put('user/address/' + t.selectedAddress.id, f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {

                        mirror(f, t.selectedAddress);

                        A.toast('保存成功');

                        t.showNewAddressForm = false;

                    }
                });
            else
                axios.post('user/address', f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {

                        if (f.is_first) {
                            t.list.address.forEach(address => {
                                address.is_first = false;
                            });

                            if (t.selectedAddress)
                                t.selectedAddress.is_first = true;
                            else
                                res.data.options.is_first = true;
                        }

                        t.list.address.unshift(res.data.options);

                        A.toast('保存成功');

                        t.showNewAddressForm = false;
                    }

                });

        },

        selectAddress(item) {
            if (this.selectMode) {
                B.addressPage.onSelect(item);
                this.$router.back(-1);
                return;
            }
            this.selectedAddress = item;
        },

        editAddress(item) {
            this.showNewAddressForm = true;
            mirror(item, this.addressForm);
        },

        newAddress() {
            this.showNewAddressForm = true;
            this.addressForm = {
                'name': '',
                'tel': '',
                'location_id': 1,
                'is_first': false,
            };
        },

        back() {
            A.safety('本次修改将不会被保存').then(res => {
                if (res.isConfirmed) {
                    this.showNewAddressForm = false;
                }
            });
        },

        removeAddress(item) {

            let t = this;

            if (t.busying)
                return;

            A.safety('确定要移除该地址吗？').then(res => {

                if (res.isConfirmed) {

                    t.busying = true;

                    axios.delete('user/address/' + item.id).then(res => {

                        t.busying = false;

                        if (res.data.status == 1) {
                            t.list.address.remove(item);
                            t.selectedAddress = null;
                        }
                    });


                }
            });
        },

        showOptions(type) {

            let t = this;

            let arr = [];

            let oneLevelId, twoLevelId, threeLevelId;

            let field = 'name';
            let relation = undefined;
            let column = 1;

            if (type == 'location') {
                arr.push(t.list.location);
                oneLevelId = t.addressForm.location_id ? t.addressForm.location_id : arr[0][0].id;
            }

            let dict = {
                'location': '送餐点',
            };

            new IosSelect(column,
                arr,
                {
                    title: dict[type],
                    itemHeight: 60,
                    relation: relation,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    field: field || 'value',
                    callback: function (obj1, obj2, obj3) {
                        if (column == 1) {
                            t.addressForm.location_id = obj1.id;
                            t.$forceUpdate();
                        }
                    }
                });
        },
    },
    data() {
        return {
            selectMode: false,
            showNewAddressForm: false,
            selectedAddress: null,
            errMsg: "",
            addressForm: {
                'name': '',
                'tel': '',
                'location_id': 1,
                'is_first': false,
            },
            list: {
                address: [],
                location: []
            },
            dict: {
                location: {}
            }
        };
    },
};
