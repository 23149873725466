


export default {
    components: {},
    created() {

    },
    activated() {
        let t = this;
        let p = getURLParams();
        if (p.status)
            t.status = p.status;
        t.loadStats();

        t.pager.load = t.loadData;

        t.pager.load();

        t.stopPolling = false;
        t.pager.enabled = true;
    },

    deactivated() {
        let t = this;
        t.stopPolling = true;
        t.pager.enabled = false;
    },
    methods: {

        buildParams() {
            let p = {};
            return p;
        },

        loadStats() {

            let t = this;

            let p = t.buildParams();

            axios.post('user/order/stats', p).then(res => {

                if (res.data.status == 1) {
                    t.stats = res.data.options.stats;
                }

            });

        },

        loadData() {

            let t = this;

            let p = t.buildParams();

            p.status = t.status;

            p.pageSize = t.pager.size;
            p.pageIndex = t.pager.index;
            t.pager.loading = true;
            axios.get('user/order', { params: p }).then(res => {
                t.pager.loading = false;
                if (res.data.status == 1) {
                    t.pager.pageTotal = res.data.options.pageTotal;
                    t.pager.itemTotal = res.data.options.itemTotal;
                    let orders = res.data.options.items;
                    if (t.pager.index == 1) {
                        window.scrollTo(0, 0);
                        t.list.order = orders;
                    }
                    else
                        t.list.order.append(orders);
                }
            });

        },



        setStatus(_s) {

            if (_s != this.status) {
                this.status = _s;
                this.loadData();
            }

        },

        showApplyReturn(order) {
            this.order = order;
            this.openModal('apply-return');
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        cancelOrder(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消该订单吗？').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/cancelOrder/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            order.status = 99;
                            t.loadStats();
                        }
                    });
                }
                else
                    t.busying = false;
            });
        },

        cancelOrder2(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消该订单吗？').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/cancelOrder_g/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            order.status = 99;
                            t.loadStats();
                        } else
                            A.err(res.data.msg);
                    });
                }
                else
                    t.busying = false;
            });
        },

        quickRefund(order) {

            let t = this;

            A.safety('确定要申请极速退款吗？<br>款项将在72小时内原路返回').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/quickRefund/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            order.status = 99;
                            t.loadStats();
                        }
                        else if (res.data.status == 2) {
                            A.show('退款失败，已经过了3分钟极速退款有效期', '请联系客服', 'error');
                            t.loadStats();
                            t.loadData();
                        }
                    });
                }
                else
                    t.busying = false;
            });
        },

        applyReturn() {
            let t = this;
            let f = t.returnForm;

            if (!f.return_reason || f.return_reason.length < 3) {
                t.errMsg = "请至少输入3个字的问题描述"
                return;
            }

            t.errMsg = "";

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/order/applyReturn/' + t.order.id, f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    t.list.order.remove(t.order);
                    t.loadStats();
                    t.closeModal();
                    A.show('很抱歉为您带来不便，我们会尽快与您联系处理，谢谢！', '申请已提交', 'success').then(res => {
                        if (res.isConfirmed) {
                        }
                    });
                    t.status = 'returning';
                    t.loadData();
                }
            });
        },

        cancelReturn(order) {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消售后申请吗？').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/cancelReturn/' + order.id).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            t.list.order.remove(order);
                            t.loadStats();
                        }
                    });
                }
            });
        },

        goOrder(order) {
            this.$router.push('/order?id=' + order.id);
        },

        removeOrder(order) {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要删除该订单吗？').then(res => {
                if (res.isConfirmed) {
                    axios.delete('user/order/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            t.list.order.remove(order);
                            t.loadStats();
                        }
                    });
                }
                else
                    t.busying = false;
            });
        },

        goPayment(order) {

            let t = this;

            t.order = order;

            t.startPollingForPaymentResult();

            wx.miniProgram.navigateTo({
                url: '/pages/payment/payment?oid=' + order.id
            });

        },

        startPollingForPaymentResult() {

            let t = this;

            axios.post('user/order/status/' + t.order.id).then(res => {

                if (res.data.status == 1) {

                    if (res.data.options.item.status == 2)
                        t.$router.replace('/paymentsuccess?oid=' + t.order.id);
                    else
                        setTimeout(() => { if (t.stopPolling) return; t.startPollingForPaymentResult() }, 1000);

                }

            });

        }
    },
    data() {
        return {
            order: null,
            modal: '',
            status: 'handling',
            errMsg: '',
            returnForm: {
                return_type: 1,
                return_reason: ''
            },
            stats: {
                handling: 0,
                completed: 0,
                returning: 0,
            },
            list: {
                order: [],
                returnType: [
                    { 'id': 1, 'name': '未收到餐' },
                    { 'id': 2, 'name': '送错餐' },
                    { 'id': 3, 'name': '质量问题' },
                    { 'id': 4, 'name': '口味不合适' },
                    { 'id': 5, 'name': '其他' },
                ]
            },
            dict: {
                status: {
                    1: "待支付",
                    2: "已支付",
                    3: "已送达",
                    99: "已取消"
                },
            },

            stopPolling: false,

            pager: {
                enabled: true,
                loading: false,
                size: 10,
                index: 1,
                pageTotal: 0,
                itemTotal: 0,
            }
        };
    },
};
