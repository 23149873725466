

export default {
    name: "order",
    created() {
        let that = this;
        that.loadData();
    },
    activated() {
        this.stopPolling = false;
    },
    deactivated() {
        this.stopPolling = true;
    },
    methods: {
        loadData() {
            let that = this;

            axios.get('user/order/' + getURLParams().id).then(res => {
                if (res.data.status == 1) {
                    let order = res.data.options.item;;
                    if (order.address)
                        that.address = JSON.parse(order.address);
                    that.order = order;
                }
            });

        },

        showApplyReturn(order) {
            this.order = order;
            this.openModal('apply-return');
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        cancelOrder(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消该订单吗？').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/cancelOrder/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            order.status = 99;
                        }
                    });
                }
                else
                    t.busying = false;
            });
        },


        cancelOrder2(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消该订单吗？').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/cancelOrder_g/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            order.status = 99;
                            t.loadStats();
                        } else
                            A.err(res.data.msg);
                    });
                }
                else
                    t.busying = false;
            });
        },

        quickRefund(order) {

            let t = this;

            A.safety('确定要申请极速退款吗？<br>款项将在72小时内原路返回').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/quickRefund/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            order.status = 99;
                        }
                    });
                }
                else
                    t.busying = false;
            });
        },

        applyReturn() {
            let t = this;
            let f = t.returnForm;

            if (!f.return_reason || f.return_reason.length < 3) {
                t.errMsg = "请至少输入3个字的问题描述"
                return;
            }

            t.errMsg = "";

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/order/applyReturn/' + t.order.id, f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    t.closeModal();
                    t.order.return_status = 1;
                    A.show('很抱歉为您带来不便，我们会尽快与您联系处理，谢谢！', '申请已提交', 'success').then(res => {
                        if (res.isConfirmed) {
                        }
                    });
                }
            });
        },

        cancelReturn(order) {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消售后申请吗？').then(res => {
                if (res.isConfirmed) {
                    axios.post('user/order/cancelReturn/' + order.id).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            t.order.return_status = 0;
                        }
                    });
                }
            });
        },

        removeOrder(order) {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要删除该订单吗？').then(res => {
                if (res.isConfirmed) {
                    axios.delete('user/order/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            t.$router.replace('/orders');
                        }
                    });
                }
            });
        },

        goPayment(order) {

            let t = this;

            t.order = order;

            t.startPollingForPaymentResult();

            wx.miniProgram.navigateTo({
                url: '/pages/payment/payment?oid=' + order.id
            });

        },

        startPollingForPaymentResult() {

            let t = this;

            if (t.app2) {
                if (t.order.company_id == 1 && t.order.status == 2 && !t.order.paid_at) {

                    axios.post('user/order/wepay_status/' + t.order.id).then(res => {

                        if (res.data.status == 1) {

                            if (res.data.options.item.status == 2 && !res.data.options.item.paid_at)
                                t.$router.replace('/paymentsuccess?oid=' + t.order.id);
                            else
                                setTimeout(() => { if (t.stopPolling) return; t.startPollingForPaymentResult() }, 1000);

                        }

                    });

                    return;
                }
            }
            axios.post('user/order/status/' + t.order.id).then(res => {

                if (res.data.status == 1) {

                    if (res.data.options.item.status == 2)
                        t.$router.replace('/paymentsuccess?oid=' + t.order.id);
                    else
                        setTimeout(() => { if (t.stopPolling) return; t.startPollingForPaymentResult() }, 1000);

                }

            });

        },

        goContact() {
            wx.miniProgram.navigateTo({
                url: '/pages/contact/contact'
            });
        },

        confirmPicked(order) {

            let t = this;
            
            if (t.busying)
                return;
            
            t.busying = true;
            
            axios.post('user/order/confirmPicked/' + order.id).then(res => {

                t.busying = false;
                
                if (res.data.status == 1) {
                    order.status = 3;
                    A.toast('订单已确认');
                }
            });

        }

    },
    data() {
        return {
            order: null,
            modal: '',
            errMsg: '',
            address: null,
            returnForm: {
                return_type: 1,
                return_reason: ''
            },
            list: {
                order: [],
                returnType: [
                    { 'id': 1, 'name': '未收到餐' },
                    { 'id': 2, 'name': '送错餐' },
                    { 'id': 3, 'name': '质量问题' },
                    { 'id': 4, 'name': '口味不合适' },
                    { 'id': 5, 'name': '其他' },
                ]
            },
            dict: {
                status: {
                    1: "待支付",
                    2: "已支付",
                    3: "已送达",
                    99: "已取消"
                },
                returnStatus: {
                    1: "要求退款",
                    2: "退款失败",
                    3: "已退款",
                    98: "退款中",
                    99: "拒绝退款",
                },
            },
            stopPolling: false,
        };
    },
};
