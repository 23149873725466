


export default {

    activated() {
        this.loadData();
    },
    methods: {
        loadData() {

            let that = this;

            axios.get('getHelps').then(res => {
                if (res.data.status == 1) {
                    let qas = res.data.options.items;
                    qas.forEach(qa => qa.opened = false);
                    that.list.qa = qas;
                }
            });

        },

    },
    data() {
        return {
            list: {
                qa: []
            },
        };
    },
};
