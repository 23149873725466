
import AppNav from "./components/nav";
import Vue from 'vue'
export default {
  name: "Index",
  components: {
    AppNav,
  },
  data() {
    return {
      disabledScroll: false
    };
  },
  methods: {
    afterLogin() {
      axios.defaults.headers.common["Token"] = this.U.token;
      axios.defaults.headers.common["OpenID"] = this.U.open_id;
      this.initSharing();
    }
  },
  created() {

    var t = this;

    Vue.prototype.dictArea = {
                2:'卓宏',
                3:'科学城',
                4:'深理工'
            };
            
    Vue.prototype.startUrl = location.href;

    axios.defaults.baseURL = 'https://' + location.host + '/u/';

    Vue.prototype.busying = false;

    Vue.prototype.toggle = {
      expandedNav: true
    };

    Vue.prototype.app2 = location.host.indexOf('ayu2') > -1;

    Vue.prototype.area = S.get('area') ? S.get('area') : (Vue.prototype.app2 ? 2 : 1);


    Vue.prototype.setArea = function(area) {
      this.area = area;
      S.set('area', area);
      axios.defaults.headers.common["Area"] = area;  
    }

    axios.defaults.headers.common["Area"] = Vue.prototype.area;

    if (/localhost/.test(location.host)) {
      Vue.prototype.app2 = true;
      axios.defaults.baseURL = 'https://ayu2.digital-event.cn/u/';
      // axios.defaults.baseURL = 'http://localhost/Archives/ay/api/public/u/';
    }

    let app2 = Vue.prototype.app2;

    if (app2) {
      document.body.classList.add('app2');
    }

    Vue.prototype.U = {
      name: '',
      avatar_url: '',
      token: '',
      id: '',
      open_id: '',
      company_id: 0
    };


    if (S.get('_u')) {
      Vue.prototype.U = S.get('_u');
      t.afterLogin();
    }

    let p = getURLParams();

    if (p.area) {

      S.set('area', p.area);

      Vue.prototype.area = S.get('area') ? S.get('area') : (Vue.prototype.app2 ? 2 : 1);

      axios.defaults.headers.common["Area"] = Vue.prototype.area;


    }

    if (p.open_id) {
      axios.post('account/signInByOpenId', { 'open_id': p.open_id }).then(res => {
        if (res.data.status == 1) {
          let U = res.data.options.item;
          mirror(U, t.U);
          S.set('_u', U);
          t.afterLogin();
        }
      });
    }

  },
  mounted() {
    let t = this;
  }

};
