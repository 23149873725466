

export default {

    activated() {
        this.loadData();
    },
    methods: {

        loadData() {
            let t = this;

            axios.get('goods/' + getURLParams().id).then(res => {
                if (res.data.status == 1) {

                    t.initItem(res.data.options.item);

                    setTimeout(() => {
                        t.initUI();

                    }, 50);


                }
            });
        },

        initItem(item) {

            let t = this;

            if (item.img_urls)
                item.img_urls_arr = item.img_urls.split(',');

            item.quantity = 1;

            for (let i = 0; i < item.options.length; i++) {

                let opt = item.options[i];

                opt.selectedLabel = opt.labels[0];

                for (let j = 0; j < opt.labels.length; j++) {
                    let label = opt.labels[j];
                    label.selected = false;
                }

                opt.labels[0].selected = true;

            }

            try {

                t.initSharing(item.name, '/pages/index/index?path=item&params=' + JSON.stringify({ 'id': item.id }), 'https://ayu.digital-event.cn/res/' + item.img_urls.split(',')[0]);

            } catch (error) {

            }

            t.item = item;
        },

        initUI() {
            let t = this;

            setTimeout(() => {

                if (document.getElementById('slider')) {
                    let swiper = new Swiper('#slider', {
                        spaceBetween: 30,
                        effect: 'fade',
                        loop: true,
                        pagination: {
                            el: '.blog-slider__pagination',
                            clickable: true,
                        }
                    });
                }
                else
                    t.initUI();

            }, 100);

        },

        showOptions(putInCart) {
            if (putInCart)
                this.nextStep = 'addToCart';
            else
                this.nextStep = 'checkout';

            this.openModal('options');
        },

        goNextStep() {
            let t = this;

            let item = t.item;

            let cartItem = {
                'id': item.id,
                'name': item.name,
                'price': item.price,
                'quantity': item.quantity,
                'img_url': item.img_urls.split(',')[0]
            };

            if (item.quantity > item.stock - item.count_sold) {
                A.toast('菜品库存不足', 1000, 'warning');
                return;
            }

            let labelIds = [];
            let labelNames = [];

            for (let i = 0; i < item.options.length; i++) {

                let opt = item.options[i];

                if (opt.selectedLabel) {
                    labelIds.push(opt.selectedLabel.id);
                    labelNames.push(opt.selectedLabel.name);
                }

            }

            if (labelIds.length > 0) {
                cartItem.label_ids = labelIds.join(',');
                cartItem.label_names = labelNames.join(',');
            }

            if (this.nextStep == 'addToCart') {

                let cartItems = JSON.parse(localStorage['cart_items'] ? localStorage['cart_items'] : '[]');

                let isNew = true;

                for (let i = 0; i < cartItems.length; i++) {

                    let _item = cartItems[i];

                    if (_item.id == cartItem.id && _item.label_ids == cartItem.label_ids) {
                        _item.quantity = Number(_item.quantity) + Number(cartItem.quantity);
                        isNew = false;
                    }
                }

                if (isNew)
                    cartItems.push(cartItem);

                localStorage['cart_items'] = JSON.stringify(cartItems);

                t.closeModal();

                A.toast('已放入购物车');

            }
            else if (this.nextStep == 'checkout') {
                B.paymentPage = {
                    from: 'item',
                    items: [cartItem]
                };
                t.$router.push('/checkout');
            }

            t.closeModal();
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        setNum(k, v) {
            this.item[k] = Number(this.item[k]) + Number(v);
            this.$forceUpdate();
        },



        selectValue(opt, label) {

            let t = this;

            for (let l in opt.labels) {
                opt.labels[l].selected = false;
            }

            opt.selectedLabel = label;

            label.selected = true;

        },

        goCart() {
            this.$router.replace('/cart');
        }
    },
    data() {
        return {
            item: null,
            modal: '',
            nextStep: '',
            quantity: 1,
            list: {
            },
            selectedLabels: [],
        };
    },
};
