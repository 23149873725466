import Vue from 'vue'
import Router from 'vue-router'

import Menu from './menu/menu'
import Cart from './cart/cart'
import Address from './address/address'
import Checkout from './checkout/checkout'

import Orders from './orders/orders'
import Order from './order/order'

import List from './list/list'
import Item from './item/item'

import Paymentsuccess from './paymentsuccess/paymentsuccess'
import Faq from './faq/faq'
import Hcs from './hcs/hcs'

import './style.css'

Vue.use(Router)
export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      window.lastPosition = savedPosition;
      return savedPosition
    } else {
      window.lastPosition = {
        x: 0,
        y: 0
      };
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [{
      path: '',
      redirect: '/menu'
    },
    {
      name: 'orders',
      path: '/orders',
      component: Orders,
    },
    {
      name: 'order',
      path: '/order',
      component: Order,
    },
    {
      name: 'list',
      path: '/list',
      component: List,
    },
    {
      name: 'item',
      path: '/item',
      component: Item,
    },
    {
      name: 'menu',
      path: '/menu',
      component: Menu,
    },
    {
      name: 'paymentsuccess',
      path: '/paymentsuccess',
      component: Paymentsuccess,
    },
    {
      name: 'faq',
      path: '/faq',
      component: Faq,
    },
    {
      name: 'address',
      path: '/address',
      component: Address,
    },
    {
      name: 'checkout',
      path: '/checkout',
      component: Checkout,
    },
    {
      name: 'cart',
      path: '/cart',
      component: Cart,
    },
    {
      name: 'hcs',
      path: '/hcs',
      component: Hcs,
    },

  ],
})