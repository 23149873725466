

export default {
    name: "checkout",
    created() {

        let t = this;

        // if (t.app2){
        //     if(!localStorage['hint']){
        //         A.show('近期刷卡支付暂停，请使用微信支付，不便之处敬请谅解。','支付说明','info').then(res=>{
        //             localStorage['hint'] = 1;
        //         });
        //     }
        // }
        if (t.app2 && t.U.company_id == 1) {
            // t.paymentWay = 2;
            t.paymentWay = 1;
            t.isHcs = true;
            t.area2 = t.area;
        }

        if (B.paymentPage)
            t.list.item = B.paymentPage.items;
        else
            t.list.item = S.get('cart_items');

        if (!t.list.item || t.list.item.length == 0) {
            t.$router.replace('cart');
        }
        else {

            let i = 0;

            t.list.item.forEach(item => {
                item._i = i;
                item.coupon = null;
                i++;
            });

            t.calcTotal();

            t.getAddressList();
        }

    },
    activated() {
        this.stopPolling = false;
    },
    deactivated() {
        this.stopPolling = true;
    },
    destroyed() {
        this.stopPolling = true;
    },
    mounted() {
        this.$refs.trans.ready = true;
    },
    methods: {

        loadSelectorData() {

            let t = this;

            axios.get('location').then(res => {
                if (res.data.status == 1) {
                    t.list.location = res.data.options.items;
                    t.dict.location = listToDict(t.list.location);

                }
            });

            axios.get('timePoint?area=' + t.area2).then(res => {
                if (res.data.status == 1) {
                    let items = res.data.options.items;

                    let today = new Date().format('yyyy/MM/dd');

                    let arr = [];

                    let curTime = new Date();

                    if (t.app2) {
                        if (curTime.getHours() >= 20) {
                            t.isNextDayOrder = true;
                        }

                        items.forEach(item => {

                            let time = new Date(today + ' ' + item.delivery_time);

                            let cur = (new Date()).valueOf() + 1000 * 60 * 105;

                            if (item.delivery_time == '17:30') {
                                if (curTime.getHours() < 13 && t.area2 != 3)
                                    return;
                                cur = (new Date()).valueOf() + 1000 * 60 * 90;
                            }

                            if (t.isNextDayOrder) {
                                if (item.delivery_time == '11:30' && curTime.getHours() >= 20) {
                                    item.delivery_time = '明天 ' + item.delivery_time;
                                    arr.push(item);
                                }
                            } else if (cur < time)
                                arr.push(item);

                        });
                    }
                    else {

                        if ((curTime.getHours() == 22 && curTime.getMinutes() > 30) || curTime.getHours() >= 23) {
                            t.isNextDayOrder = true;
                        }

                        items.forEach(item => {

                            let time = new Date(today + ' ' + item.delivery_time);

                            //当前下单时间必须与送达时间至少35m的间隔
                            //例如11点56分之后下单，不能选择12:30作为送餐时间
                            let cur = (new Date()).valueOf() + 1000 * 60 * 35;

                            //午市与晚市的最后一趟为15m间隔
                            if (item.delivery_time == '13:30' || item.delivery_time == '19:30')
                                cur = (new Date()).valueOf() + 1000 * 60 * 10;

                            if (cur < time)
                                arr.push(item);
                            else if (t.isNextDayOrder) {
                                item.delivery_time = '明天 ' + item.delivery_time;
                                arr.push(item);
                            }
                        });
                    }

                    t.list.timePoint = arr;

                    t.selectedTimepoint = arr[0];

                    t.dict.timePoint = listToDict(t.list.timePoint);
                }
            });

        },

        calcTotal() {

            let t = this;

            let amount = 0;
            let packageFee = 0;

            t.list.item.forEach(item => {
                // packageFee += Number(item.quantity);
                amount += item.price * item.quantity;
            });

            t.total.amount = amount;
            // t.total.package_fee = packageFee;
            t.total.payment_amount = t.total.amount + t.total.package_fee;

        },

        getAddressList() {

            let t = this;

            axios.get('user/address').then(res => {
                if (res.data.status == 1) {
                    t.list.address = res.data.options.items;
                    if (t.list.address.length > 0) {
                        t.list.address.forEach(item => {
                            if (item.is_first == 1)
                                t.selectedAddress = item;
                        });
                        if (!t.selectedAddress)
                            t.selectedAddress = t.list.address[0];

                        // if (t.selectedAddress.location_id == 3) {
                        //     t.enabledCardPay = false;
                        //     t.paymentWay = 2;
                        // }
                        t.area2 = t.selectedAddress.location_id;
                    } else {
                        if (t.list.location.length == 1)
                            t.addressForm.location_id = t.list.location[0].id;
                        t.showNewAddressForm = true;
                        t.addressForm.is_first = true;
                    }

                    t.loadSelectorData();
                }
            });

        },

        saveAddress() {

            let t = this;

            let f = t.addressForm;

            if (!f.location_id) {
                t.errMsg = "请选择送餐点";
                return;
            }

            if (!f.name) {
                t.errMsg = "请输入收件人名称";
                return;
            }

            if (!/^1\d{10}$/.test(f.tel)) {
                t.errMsg = "请输入手机号(11位数字)";
                return;
            }

            if (t.busying)
                return;
            t.busying = true;

            axios.post('user/address', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    if (f.is_first) {
                        t.list.address.forEach(address => {
                            address.is_first = false;
                        });

                        if (t.selectedAddress)
                            t.selectedAddress.is_first = true;
                        else
                            res.data.options.is_first = true;
                    }

                    t.list.address.unshift(res.data.options);

                    A.toast('保存成功');

                    t.showNewAddressForm = false;

                    t.selectAddress(t.list.address[0]);
                }

            });

        },

        selectAddress(item) {
            let t = this;
            t.selectedAddress = item;
            // if (t.selectedAddress.location_id == 3) {
            //     t.enabledCardPay = false;
            //     t.paymentWay = 2;
            // }
            t.area2 = item.location_id;
            t.loadSelectorData();
        },

        showOptions(type) {

            let t = this;

            let arr = [];

            let oneLevelId, twoLevelId, threeLevelId;

            let field = 'name';
            let relation = undefined;
            let column = 1;

            if (type == 'location') {
                arr.push(t.list.location);
                oneLevelId = t.addressForm.location_id ? t.addressForm.location_id : arr[0][0].id;
            }

            if (type == 'timePoint') {
                field = 'delivery_time';
                arr.push(t.list.timePoint);
                oneLevelId = t.selectedTimepoint ? t.selectedTimepoint.id : arr[0][0].id;
            }

            let dict = {
                'location': '送餐点',
                'timePoint': '送餐时间',
            };

            new IosSelect(column,
                arr,
                {
                    title: dict[type],
                    itemHeight: 60,
                    relation: relation,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    field: field || 'value',
                    callback: function (obj1, obj2, obj3) {
                        if (column == 1) {
                            if (type == 'location') {
                                t.addressForm.location_id = obj1.id;
                            }

                            if (type == 'timePoint') {
                                t.selectedTimepoint = obj1;
                            }
                            t.$forceUpdate();
                        }
                    }
                });
        },

        launchPayment() {

            let t = this;

            // if (t.submitted)
            //     return;

            if (!t.selectedAddress) {
                A.toast('请指定您的送餐地址', 1000, 'info');
                return;
            }

            let goodsData = pick(t.list.item, ['id', 'quantity', 'label_ids', 'label_names', '_i']);

            if(t.app2){
                if(t.selectedAddress.location_id == 1){
                    A.err('请修改您的送餐地址，重新指定送餐地点');
                    return;
                }
            }

            let f = {
                'json_items': goodsData,
                'address_id': t.selectedAddress.id,
                'time_point_id': t.selectedTimepoint.id,
                'next_day': t.isNextDayOrder ? 1 : 0,
                'remark': t.remark
            };


            function _save() {

                if (t.busying)
                    return;

                let dict = listToDict(t.list.item);
                
                t.busying = true;

                if (t.isHcs && t.paymentWay == 1)
                    f.payment_way = 'hcs';

                    
                axios.post('menu/checkStock', { 'json_items': JSON.stringify(goodsData), 'area': t.area2 }).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {

                        t.busying = true;

                        axios.post('user/order/submit', f).then(res => {

                            t.busying = false;

                            if (res.data.status == 1) {

                                t.order = res.data.options.item;

                                // t.submitted = true;

                                if (B.paymentPage && B.paymentPage.from == 'cart') {
                                    localStorage['cart_items'] = JSON.stringify(B.paymentPage.unselectedItems ?? []);
                                }

                                if (f.payment_way == 'hcs') {
                                    t.$router.replace('/paymentsuccess?oid=' + t.order.id);
                                }
                                else {
                                    wx.miniProgram.navigateTo({
                                        url: '/pages/payment/payment?oid=' + res.data.options.item.id
                                    });

                                    t.startPollingForPaymentResult();
                                }
                            }
                        });

                    }
                    else if (res.data.status == 2) {
                        A.show('亲，今天已经暂停下单了哦~<br>不便之处请多多包容，欢迎明天再来下单', '今天暂停接单', 'info');
                    }
                    else {
                        let msg = [];
                        let dictStock_notEnoughStock = res.data.options.not_enough_stock;
                        let goodsIds_notInMenu = res.data.options.not_in_menu;

                        for (const id in dictStock_notEnoughStock) {
                            if (dict[id])
                                msg.push(dict[id].name + ': 库存不足，剩余' + dictStock_notEnoughStock[id]);
                        }

                        for (let i = 0; i < goodsIds_notInMenu.length; i++) {
                            let id = goodsIds_notInMenu[i];
                            if (dict[id])
                                msg.push(dict[id].name + ': 今日未上架，请移除');
                        }

                        A.show(msg.join('<br>'), '订单被拦截', 'warning');
                    }
                });


            }


            if (t.app2) {
                if (t.selectedAddress.location_id == 3) {
                    // if (t.paymentWay == 1) {
                    //     t.paymentWay = 2;
                    //     A.toast('科学城订餐仅支持微信支付，已自动切换。');
                    // }
                    t.setArea(3);
                }
                else {
                    t.setArea(2);
                }
                let d = new Date();

                if (((d.getHours() >= 9 && d.getMinutes() >= 40) || d.getHours() > 9) && d.getHours() < 15 && t.selectedTimepoint.id == 8) {
                    Swal.mixin({
                        customClass: {
                            confirmButton: 'btn-lg btn-orange',
                            cancelButton: 'btn-sm btn-dark mr10'
                        },
                        cancelButtonText: '取消',
                        confirmButtonText: '确认下单',
                        showCancelButton: true,
                        buttonsStyling: false,
                    }).fire('温馨提示', '您的取餐时间为 ' + t.selectedTimepoint.delivery_time + ' 请确认。', 'warning').then(res => {
                        if (res.isConfirmed) {
                            _save();
                        }
                    });
                }
                else
                    _save();
            }
            else
                _save();


        },

        startPollingForPaymentResult() {

            let t = this;

            axios.post('user/order/status/' + t.order.id).then(res => {

                if (res.data.status == 1) {

                    if (res.data.options.item.status == 2)
                        t.$router.replace('/paymentsuccess?oid=' + t.order.id);
                    else
                        setTimeout(() => { if (t.stopPolling) return; t.startPollingForPaymentResult() }, 1000);

                }

            });

        }
    },
    data() {
        return {
            enabledCardPay: true,
            order: null,
            showNewAddressForm: false,
            // submitted: false,
            total: {
                package_fee: 0,
                amount: 0,
                payment_amount: 0,
            },
            selectedAddress: null,
            errMsg: "",
            remark: "",
            addressForm: {
                'name': '',
                'tel': '',
                'location_id': '',
                'is_first': false,
            },
            list: {
                item: [],
                address: [],
                location: [],
                timePoint: []
            },
            dict: {
                location: {},
                timePoint: {}
            },
            selectedTimepoint: null,
            isNextDayOrder: false,
            stopPolling: false,
            paymentWay: 1,
            isHcs: false,
            area2: 1

        };
    },
};
