
export default {

    activated() {
        this.loadSelectorData();
        this.loadData();

        this.areaText = this.dictArea[this.area];
    },

    methods: {

        switchArea() {
            let t = this;
            let a = t.area;
            a++;
            if(a > 4)
             a = 2;
            
            t.setArea(a);

            t.areaText = t.dictArea[this.area];

            A.toast('已切换到' + t.areaText +  '菜单');

            t.loadData();
        },

        loadSelectorData() {

            let t = this;

            axios.get('category').then(res => {
                if (res.data.status == 1) {
                    let items = res.data.options.items;
                    items.forEach(item => {
                        item.count_goods = 0;
                    });
                    t.list.category = items;
                    t.dict.category = listToDict(t.list.category);
                    t.curCate = t.list.category[0];
                }
            });

        },

        loadData() {

            let t = this;

            let params = {};

            axios.get('menu', { params: params }).then(res => {
                if (res.data.status == 1) {


                    if (res.data.options.config && res.data.options.config.status != 1) {
                        t.viewStatus = 2;
                        t.config = res.data.options.config;
                    }

                    else {
                        t.viewStatus = 1;
                        t.list.category.forEach(category=>{
                            category.count_goods = 0;
                        })
                        res.data.options.items.forEach(item => {
                            let category = t.dict.category[item.goods.cate_id];
                            if (category)
                                category.count_goods++;
                        });
                        t.list.item = res.data.options.items;
                    }
                }
                else if (res.data.status == 3) {
                    t.viewStatus = 3;

                }
            });

        },

        itemFilter(items) {

            let t = this;

            let s = t.search;

            let arr = [];

            items.forEach(item => {

                if (
                    ((!s.text || (s.text && item.goods.name.indexOf(s.text) > -1)) &&
                        (!t.curCate || (t.curCate && item.goods.cate_id == t.curCate.id)))
                )
                    arr.push(item);
            });

            return arr;
        },

        selectCategory(c) {
            this.curCate = c;
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        goNext(menu) {
            if (menu.stock - menu.count_sold < 1) {
                return;
            }
            this.initItem(menu);
            this.openModal('options');
        },

        initItem(menu) {

            let t = this;

            let item = menu.goods;

            item.quantity = 1;
            item.stock = menu.stock;
            item.count_sold = menu.count_sold;

            for (let i = 0; i < item.options.length; i++) {

                let opt = item.options[i];

                opt.selectedLabel = opt.labels[0];

                for (let j = 0; j < opt.labels.length; j++) {
                    let label = opt.labels[j];
                    label.selected = false;
                }

                opt.labels[0].selected = true;

            }

            t.curGoods = item;
        },


        setNum(k, v) {
            this.curGoods[k] = Number(this.curGoods[k]) + Number(v);
            this.$forceUpdate();
        },

        selectValue(opt, label) {

            let t = this;

            for (let l in opt.labels) {
                opt.labels[l].selected = false;
            }

            opt.selectedLabel = label;

            label.selected = true;

        },

        goNextStep() {
            let t = this;

            let item = t.curGoods;

            let cartItem = {
                'id': item.id,
                'name': item.name,
                'price': item.price,
                'quantity': item.quantity,
                'img_url': item.img_urls.split(',')[0]
            };

            if (!cartItem.quantity) {
                A.toast('请输入正确的数量', 1000, 'error')
                return;
            }

            if (cartItem.quantity > (item.stock - item.count_sold)) {
                A.toast('菜品库存不足', 1000, 'error')
                return;
            }

            let labelIds = [];
            let labelNames = [];

            for (let i = 0; i < item.options.length; i++) {

                let opt = item.options[i];

                if (opt.selectedLabel) {
                    labelIds.push(opt.selectedLabel.id);
                    labelNames.push(opt.selectedLabel.name);
                }

            }

            if (labelIds.length > 0) {
                cartItem.label_ids = labelIds.join(',');
                cartItem.label_names = labelNames.join(',');
            }

            if (this.nextStep == 'addToCart') {

                let cartItems = JSON.parse(localStorage['cart_items'] ? localStorage['cart_items'] : '[]');

                let isNew = true;

                for (let i = 0; i < cartItems.length; i++) {

                    let _item = cartItems[i];

                    if (_item.id == cartItem.id && _item.label_ids == cartItem.label_ids) {
                        _item.quantity = Number(_item.quantity) + Number(cartItem.quantity);
                        isNew = false;
                    }
                }

                if (isNew)
                    cartItems.push(cartItem);

                localStorage['cart_items'] = JSON.stringify(cartItems);

                t.closeModal();

                A.toast('已放入购物车');

            }
            else if (this.nextStep == 'checkout') {
                B.paymentPage = {
                    from: 'item',
                    items: [cartItem]
                };
                t.$router.push('/checkout');
            }

            t.closeModal();
        },

        goItem(item) {
            this.$router.push('/item?id=' + item.goods.id);
        }

    },
    data() {
        return {
            areaText: '',

            list: {
                category: [],
                item: []
            },

            dict: {
                category: {}
            },

            search: {
                text: '',
            },

            modal: '',

            errMsg: '',

            curCate: null,

            nextStep: '',

            curGoods: null,

            selectedLabels: [],

            viewStatus: 0,

            config: null

        };
    },
};
