
export default {

    activated() {

        let t = this;

        t.list.item = [];

        let cartItems = JSON.parse(localStorage['cart_items'] ? localStorage['cart_items'] : '[]');

        for (let i = 0; i < cartItems.length; i++) {
            if (cartItems[i].selected == undefined)
                cartItems[i].selected = true;
        }

        t.list.item = cartItems;

        t.calcTotal();
    },

    deactivated() {
    },

    methods: {
        selectAllToggle() {
            let t = this;

            t.allIsChecked = !t.allIsChecked;
            for (let i = 0; i < t.list.item.length; i++) {
                let item = t.list.item[i];
                item.selected = t.allIsChecked;
            }
            t.calcTotal();
        },
        selectToggle(item) {
            item.selected = !item.selected;
            this.calcTotal();
        },

        setNum(item, num) {
            item.quantity = Number(item.quantity) + num;
            if (item.quantity < 1)
                item.quantity = 1;

            event.stopPropagation();
            this.calcTotal();
        },

        removeItems() {

            let t = this;

            let arr = [];

            for (let i = 0; i < t.list.item.length; i++) {
                let item = t.list.item[i];
                if (item.selected)
                    arr.push(item);
            }

            if (arr.length > 0) {
                A.safety('确定要移除选中的商品吗？').then(res => {
                    if (res.isConfirmed) {
                        for (let i = 0; i < arr.length; i++) {
                            t.list.item.remove(arr[i]);
                        }
                        t.calcTotal();
                    }
                });
            }
        },

        calcTotal() {

            let t = this;

            let amount = 0;

            for (let i = 0; i < t.list.item.length; i++) {
                let item = t.list.item[i];
                if (item.selected)
                    amount += Number((item.price * item.quantity).toFixed(1));
            }

            t.amount = amount;

            localStorage['cart_items'] = JSON.stringify(this.list.item);
        },

        _goCheckout() {

            let t = this;

            if (t.busying)
                return;

            let goodsData = pick(filter(t.list.item, item => item.selected), ['id', 'quantity', 'label_ids', 'label_names', '_i']);

            if (goodsData.length == 0) {
                A.toast('请至少勾选1个菜品', 1000, 'warning');
                return;
            }
            
            let dict = listToDict(t.list.item);

            t.busying = true;

            axios.post('menu/checkStock', { 'json_items': JSON.stringify(goodsData) }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    B.paymentPage = {
                        from: 'cart',
                        items: filter(t.list.item, item => item.selected),
                        unselectedItems: filter(t.list.item, item => !item.selected)
                    };
                    t.$router.push('/checkout');
                }
                else if (res.data.status == 2) {
                    A.show('亲，今天已经暂停下单了哦~<br>不便之处请多多包容，欢迎明天再来下单', '今天暂停接单', 'info');
                }
                else {
                    let msg = [];
                    let dictStock_notEnoughStock = res.data.options.not_enough_stock;
                    let goodsIds_notInMenu = res.data.options.not_in_menu;

                    for (const id in dictStock_notEnoughStock) {
                        if (dict[id])
                            msg.push(dict[id].name + ': 库存不足，剩余' + dictStock_notEnoughStock[id]);
                    }

                    for (let i = 0; i < goodsIds_notInMenu.length; i++) {
                        let id = goodsIds_notInMenu[i];
                        if (dict[id])
                            msg.push(dict[id].name + ': 今日未上架，请移除');
                    }

                    A.show(msg.join('<br>'), '订单被拦截', 'warning');
                }
            });

        },

        goCheckout() {

            let t = this;

            if (t.busying)
                return;

            let idArr = [];

            let dict = listToDict(t.list.item);

            t.list.item.forEach(item => {
                idArr.push(item.id);
            });

            t.busying = true;

            axios.post('goods/checkExists', { 'ids': idArr.join(',') }).then(res => {

                t.busying = false;
                
                if (res.data.status == 1) {
                    if (res.data.options.not_exists_ids) {
                        let notExistsIdArr = res.data.options.not_exists_ids.split(',');
                        let msg = [];
                        notExistsIdArr.forEach(id => {
                            if (dict[id]) {
                                msg.push(dict[id].name + ': 已下架并移除');
                                t.list.item.remove(dict[id]);
                            }
                        });
                        t.$forceUpdate();
                        A.show(msg.join('<br>') + "<br>请确认后点击「去结账」", '菜品更新', 'warning');
                    }
                    else
                        t._goCheckout();
                }
            });

        }

    },
    data() {
        return {
            list: {
                item: [],
            },
            allIsChecked: true,
            amount: 0
        };
    },
    computed: {
        left_items: (t) => {
            let arr = [];
            for (let i = 0; i < t.list.item.length; i++) {
                if (i % 2 == 0) {
                    arr.push(t.list.item[i]);
                }
            }
            return arr;
        },
        right_items: (t) => {
            let arr = [];
            for (let i = 0; i < t.list.item.length; i++) {
                if (i % 2 != 0) {
                    arr.push(t.list.item[i]);
                }
            }
            return arr;
        }
    }
};
