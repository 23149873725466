

export default {

    created() {
        let t = this;

        // if (t.U.company_id == 1) {
        //     t.$router.replace('/menu');
        // }

        t.isShown = true;

    },
    activated() {
        this.loadData();
    },
    methods: {
        loadData() {


        },

        confirm() {
            let t = this;

            let f = t.profileForm;

            if (!f.name) {
                t.errMsg = "请输入真实姓名";
                return;
            }

            if (!/^1\d{10}$/.test(f.tel)) {
                t.errMsg = "请输入手机号(11位数字)";
                return;
            }

            if (!/\d{4}/.test(t.profileForm.password)) {
                t.errMsg = "请输入4位纯数字密码";
                return;
            }

            axios.post('hcs/checkPassword', t.profileForm).then(res => {
                if (res.data.status == 1) {
                    t.U.company_id = 1;
                    S.set('_u', t.U);
                    t.$router.replace('/menu');
                    A.show("已登记到企业「合成所」，祝您用餐愉快", "账号登记完成", "success");
                }
                else
                    A.err("密码错误");
            });

        }

    },
    data() {
        return {
            errMsg: "",
            profileForm: {
                'name': '',
                'tel': '',
                'cid': 1,
                'password': ''
            },
            isShown: false
        };
    }
};
