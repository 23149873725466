

export default {
    name: "paymentsuccess",
    created() {

        let t = this;

        axios.get('user/order/' + getURLParams().oid).then(res => {
            if (res.data.status == 1) {
                let order = res.data.options.item;
                t.order = order;
                if (order.address)
                    t.address = JSON.parse(order.address);
                t.list.item = t.order.items;
                setTimeout(() => {
                    t.$refs.trans.ready = true;
                }, 100);
            }
        });


    },
    mounted() {

    },
    methods: {
        goOrders() {
            this.$router.replace('/orders');
        },
        goHome() {
            this.$router.replace('/menu');
        },
    },
    data() {
        return {
            order: null,
            address: null,
            list: {
                item: [],
            }
        };
    },
};
